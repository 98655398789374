export const booking = {
    common: {
        timer: {
            processing:'The booking will expire in 20 minutes and the process will need to be repeated.',
            over: 'The booking has expired',
            title: 'Booking',
        },
        info_titles: {
            location: 'Location',
            floor: 'Floor',
            finishing: 'Finishing',
            apartment_price: 'The cost of the realty',
            booking_price: 'The cost of booking',
            purchaseTerms: 'Terms of purchase',
            booking_period: 'Booking period',
        },
        navigation: {
            objects: "Objects",
        },
        statuses: {
            client: {
                in_process: 'In process',
                booked_until: 'Booked until',
                booked: 'Booked',
                awaiting_confirmation: 'Awaiting confirmation',
                expired: 'Not completed',
                cancelled: 'The contract has not been signed',
                refusal: 'Refusal',
            },
            tooltips: {
                wait: 'The booking order has been accepted. The funds on the card are blocked; they will be debited after the booking is confirmed by the manager.',
                refusal: 'The object is unavailable. The money will be returned to the card. We will help you choose a new one.',
            }
        },
    },
    client: {
        purchase_conditions: {
            title: 'Choose a purchase method',
            tooltip: 'Step 1 - Choosing a purchase method',
            text: 'For your convenience, we offer several options for purchasing real estate. Please choose the payment method that suits you best:',
            types: {
                mortgage: 'Mortgage',
                installment: 'Installment',
                immediate: 'Full payment'
            }
        },
        personal_data: {
            title: 'Enter Personal Information',
            tooltip: 'Step 2 - Entering Personal Data',
            text: 'To formalize the contract, we need your data and mail to send a copy. Make sure that you have entered the details of the apartment owner.',
            form: {
                emailInfo: 'A booking confirmation will be sent to this email',
            },
        },
        offer: {
            title: 'Offer Agreement',
            tooltip: 'Step 3 - Acceptance of the Offer Agreement',
        },
        payment: {
            title: 'Payment',
            tooltip: 'Step 4 - Payment',
        },
        bookings: {
            title: 'Your booking',
            list: {
                empty: 'The list of objects is currently empty',
                emptyDescription: 'After the purchase, all your objects will appear here',
                toRealtyCatalog: 'Go to the catalog',
            },
            button: {
                information: 'Information',
                continue: 'To booking',
                awaiting_confirm: 'Awaiting confirmation',
                credit: 'Mortgage Application',
                pay: 'Pay the entire cost',
            },
            tooManyRequests: {
                title: 'Complete the booking',
                description: 'You already have two incomplete bookings. To create a new one, please complete one of the current bookings: pay for it or wait for the booking time to expire.',
                toList: 'Go to the list of bookings'
            },
        },
        summary: {
            title: 'Your booking',
            info: {
                booking: 'Booking',
                location: 'Location',
                realty_info: 'Information about the object',
                titles: {
                    booking_price: 'The cost of booking',
                    term: 'Term',
                },
            },
            download_offer: 'Download the offer agreement',
        },
    },
    admin: {
        registry: {
            title: 'Bookings',
            headers: {
                client: 'Client',
                realty: 'Realty',
                tariff: 'Tariff',
                price: 'Price',
                method: 'Method',
                end_date: 'Period up to',
                status: 'Status',
                contract: 'Contract',
            },
        },
    },
};
