import { en } from 'vuetify/locale';
import { settlement } from '~/plugins/i18n/en/settlement.en';
import { forms } from '~/plugins/i18n/en/forms.en';
import { coupon } from '~/plugins/i18n/en/coupon.en';
import { users } from '~/plugins/i18n/en/users.en';
import { booking } from '~/plugins/i18n/en/booking.en';

export const english = {
    $vuetify: { ...en },
    main: {
        hello: 'Welcome!!!',
        logout: 'Sign out'
    },
    login: {
        title: 'Log in to your personal account',
        subtitle: 'and unlock all features',
        loginTitle: 'Login',
        phoneTitle: 'Phone number',
        passwordTitle: 'Password',
        codeTitle: 'Code',
        btnSendCode: 'Send code',
        repeatSending: 'Resend',
        otherNumber: 'Another number',
        btnAuth: 'Authorize',
        titleSendCode: 'Enter the SMS code sent',
        subtitleSendCode: 'to the number ',
        validations: {
            requiredPhoneNumber: 'Phone number is required',
            requiredSmsCode: 'SMS code is required',
            requiredLogin: 'Login is required',
            requiredPassword: 'Password is required',
            requiredCaptcha: 'You need to confirm that you are not a bot.',
            invalidCode: 'Invalid code',
            invalidLoginPassword: 'Invalid username or password'
        }
    },
    error: {
        title: 'Error: ',
        mainPage: 'Main page',
        pageNotFound: 'Page is not found',
        accessDenied: 'Access denied',
        data: 'Data could not be retrieved',
    },
    routes: {
        menu: 'Menu',
        main:'Home',
        profile:'Profiles',
        partners: 'Privilege club',
        favorites: 'Favorites',
        administration: 'Administration',
        users: 'Users',
        emailVerified: 'Email confirmed',
        categories: 'Categories of partners',
        partner: 'Partner',
        settlement: 'Objects', 
        settlementMonitoring: 'Monitoring', 
        error: 'Error',
        promocodes: 'Promocodes',
        certificates: 'Certificates',
        transactions: 'Transactions',
        login: 'Authorization',
        acceptances: "Acceptance appointments",
        booking: "Booking",
        bookings: "Bookings",
    },
    email: {
        emailConfirmed: 'Your email has been confirmed',
        goToCabinet: 'Go to the cabinet',
        follow: 'Follow'
    },
    realty: {
        floor: 'Floor',
        apartment: 'Flat',
        parking_space: 'Parking space',
        storage: 'Storage',
        non_residential: 'Non residential',
        undefined: "Indefinite",
        rooms: 'rooms',
        price: 'price',
        section: 'Section',
        building: 'Building',
        finishing: 'Finishing',
        kitchen: 'Kitchen',
        furniture: 'Furniture',
        address: 'Address',
        square_m2: 'Area in м&sup2;',
    },
    coupon: coupon,
    users: users,
    forms: forms,
    settlement: settlement,  
    booking: booking,  
}